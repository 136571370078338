<template>
  <div>
    <v-tabs :height="60" background-color="primary" dark slider-color="accent" v-model="activeTab">
      <v-tab :key="0">Sales</v-tab>
      <v-tab :key="1">Materials</v-tab>
    </v-tabs>
    <component :is="tabComponents[activeTab]"></component>
  </div>
</template>
<script>
import SalesPerformance from '@/components/analytics/SalesPerformance'
import MaterialsInformation from '@/components/analytics/MaterialsInformation'

export default {
  name: 'analytics',
  components: { SalesPerformance, MaterialsInformation },
  data() {
    return {
      activeTab: 0,
      tabComponents: ['SalesPerformance', 'MaterialsInformation']
    }
  }
}
</script>
<style lang="scss" scoped></style>
