<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="d-flex align-center my-4" align-center>
        <div class="d-flex align-center">
          <v-select :items="views" v-model="activeView" hide-details dense @change="switchView" class="mt-0">
          </v-select>
          <div class="d-flex">
            <v-checkbox
              hide-details
              v-model="includeMissingRegion"
              label="Include Missing Region"
              class="mx-6 pa-0 mt-0"
              @change="parseData"
            ></v-checkbox>
            <v-checkbox
              hide-details
              v-model="includeUk"
              label="Include UK"
              class="mx-6 pa-0 mt-0"
              @change="parseData"
            ></v-checkbox>
            <v-checkbox
              hide-details
              v-model="includeEu"
              label="Include EU"
              class="mr-6 pa-0 mt-0"
              @change="parseData"
            ></v-checkbox>
            <v-checkbox
              hide-details
              v-model="includeUs"
              label="Include US"
              class="pa-0 mt-0"
              @change="parseData"
            ></v-checkbox>
          </div>
        </div>
        <v-spacer />
        <DateRangePicker @change="loadData" align="right"></DateRangePicker>
      </v-col>
      <v-col cols="12">
        <LineChart :width="1000" v-if="!loadingData" :chartdata="chartData" :options="chartOptions"></LineChart>
        <v-progress-circular v-else class="mt-4" :size="30" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DateRangePicker from '@/components/ui/DateRangePicker'
import LineChart from '@/components/analytics/charts/LineChart'
import AnalyticsSettings from '@/js/analytics-settings'

export default {
  name: 'sales-performance',
  components: { DateRangePicker, LineChart },
  data() {
    return {
      loadingData: true,
      dataSnap: {},
      parsedData: null,
      includeMissingRegion: false,
      includeUs: false,
      includeUk: true,
      includeEu: false,
      chartData: {
        type: null,
        labels: null,
        datasets: null
      },
      views: [
        { text: 'Sales', value: 'revenue' },
        { text: 'Quotes', value: 'quotes' },
        { text: 'Conversion', value: 'conversion' },
        { text: 'FOC Orders', value: 'foc' },
        { text: 'Avg Order Value', value: 'avg-order-val' },
        { text: 'Avg Wait Duration', value: 'avg-wait-duration' }
      ],
      activeView: 'revenue',
      chartOptions: {
        plugins: {
          legend: {
            position: 'left',
            align: 'center',
            labels: {
              usePointStyle: false
            }
          }
        },
        scales: {
          yAxis: {
            suggestedMax: 50
          }
        }
      },
      colours: ['#F44336', '#9C27B0', '#2196F3', '#009688', '#FF9800', '#607D8B']
    }
  },
  methods: {
    async loadData(range) {
      this.loadingData = true

      let months = []
      let dateStart = this.$moment(range[0])
      let dateEnd = this.$moment(range[1]).endOf('day')

      while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
        months.push(dateStart.format('MMM YY'))
        dateStart.add(1, 'month')
      }

      const quotesSnap = await this.$db
        .collection('quoteIndex')
        .where('dateCreated', '>=', range[0].toDate())
        .where('dateCreated', '<=', range[1].endOf('day').toDate())
        .get()
      const ordersSnap = await this.$db
        .collection('orders')
        .where('dateCreated', '>=', range[0].toDate())
        .where('dateCreated', '<=', range[1].endOf('day').toDate())
        .get()
      const durationSnap = await this.$db
        .collection('quoteDurations')
        .where('waitingForQuote', '>=', range[0].toDate())
        .where('waitingForQuote', '<=', range[1].endOf('day').toDate())
        .get()

      this.dataSnap = {
        quotes: quotesSnap,
        orders: ordersSnap,
        durations: durationSnap
      }

      this.chartData.labels = months
      this.parseData()
      this.loadingData = false
    },
    async parseData() {
      let data = { combined: {} }

      for (const quote of this.dataSnap.quotes.docs) {
        if (
          (!this.includeMissingRegion && !quote.data().region) ||
          (!this.includeUk && quote.data().region === 'uk') ||
          (!this.includeEu && quote.data().region === 'eu') ||
          (!this.includeUs && quote.data().region === 'us')
        )
          continue

        const month = this.$moment.unix(quote.data().dateCreated.seconds).format('MMM YY')
        if (!data[quote.data().creatorEmail]) data[quote.data().creatorEmail] = {}

        // create or increment creator quote count
        if (!data[quote.data().creatorEmail][month])
          data[quote.data().creatorEmail][month] = {
            quotes: 0,
            convertedQuotes: 0,
            revenue: 0,
            focs: 0,
            orders: 0,
            primaryRevenue: 0,
            primaryOrders: 0,
            orderIds: [],
            revenueFromQuotes: 0
          }
        data[quote.data().creatorEmail][month].quotes++

        // create or increment combined quote count
        if (!data.combined[month])
          data.combined[month] = {
            quotes: 0,
            convertedQuotes: 0,
            revenue: 0,
            focs: 0,
            orders: 0,
            primaryRevenue: 0,
            primaryOrders: 0,
            revenueFromQuotes: 0,
            quotesWaiting: 0,
            totalWaitingDuration: 0
          }
        data.combined[month].quotes++

        if (quote.data().orders) {
          data[quote.data().creatorEmail][month].convertedQuotes++
          data.combined[month].convertedQuotes++
        }
      }

      for (const order of this.dataSnap.orders.docs) {
        if (order.data().type === 'sample-pack') continue

        if (
          (!this.includeUk && order.data().region === 'uk') ||
          (!this.includeEu && order.data().region === 'eu') ||
          (!this.includeUs && order.data().region === 'us')
        )
          continue

        const month = this.$moment.unix(order.data().dateCreated.seconds).format('MMM YY')
        if (!data[order.data().creatorEmail]) data[order.data().creatorEmail] = {}

        if (!data[order.data().creatorEmail][month])
          data[order.data().creatorEmail][month] = {
            quotes: 0,
            convertedQuotes: 0,
            revenue: 0,
            focs: 0,
            orders: 0,
            primaryRevenue: 0,
            primaryOrders: 0,
            orderIds: []
          }

        // TODO update old USD orders with 'total' param to value or vice versa
        let amount = 'total' in order.data() ? order.data().total : order.data().value

        // very rough USD to GBP conversion
        if (order.data().region === 'us') {
          amount = amount * 0.75
        } else {
          amount = this.$utils.invoiceValInGBP(order.data())
        }

        if (!data.combined[month]) {
          data.combined[month] = {
            revenue: 0,
            primaryRevenue: 0,
            primaryOrders: 0
          }
        }

        data[order.data().creatorEmail][month].revenue += amount
        data[order.data().creatorEmail][month].orders += 1
        data[order.data().creatorEmail][month].orderIds.push(order.id)

        data.combined[month].revenue += amount
        data.combined[month].orders += amount

        if (!order.data().foc) {
          data[order.data().creatorEmail][month].primaryRevenue += amount
          data[order.data().creatorEmail][month].primaryOrders += 1
          data.combined[month].primaryRevenue += amount
          data.combined[month].primaryOrders += 1
        }

        if (amount === 0 || order.data().foc) {
          data[order.data().creatorEmail][month].focs += 1
          data.combined[month].focs += 1
        }
      }

      for (const duration of this.dataSnap.durations.docs) {
        if (duration.data().quoteSent) {
          const waiting = this.$moment.unix(duration.data().waitingForQuote.seconds)
          const quoted = this.$moment.unix(duration.data().quoteSent.seconds)
          const dur = quoted.diff(waiting, 'hours') / 24

          data.combined[waiting.format('MMM YY')].quotesWaiting++
          data.combined[waiting.format('MMM YY')].totalWaitingDuration += dur
        }
      }

      this.parsedData = data

      let datasets
      switch (this.activeView) {
        case 'revenue':
          datasets = this.getRevenueDatasets()
          break
        case 'quotes':
          datasets = this.getQuotesDatasets()
          break
        case 'conversion':
          datasets = this.getConversionDatasets()
          break
        case 'foc':
          datasets = this.getFocDatasets()
          break
        case 'avg-order-val':
          datasets = this.getAvgOrderDatasets()
          break
        case 'avg-wait-duration':
          datasets = this.getAvgWaitDatasets()
          break
      }

      this.chartData.datasets = datasets
    },
    getAvgWaitDatasets() {
      let o = {
        data: [],
        borderColor: '#000000',
        backgroundColor: '#000000',
        borderWidth: 2,
        pointStyle: 'rectRot',
        fill: false,
        tension: 0,
        label: 'Team'
      }

      for (const month of this.chartData.labels) {
        let m = this.parsedData.combined[month]
        if (m.totalWaitingDuration > 0) {
          o.data.push(m.totalWaitingDuration / m.quotesWaiting)
        } else {
          o.data.push(0)
        }
      }

      return [o]
    },
    getRevenueDatasets() {
      let revenueData = []

      for (const [quotee, metrics] of Object.entries(this.parsedData)) {
        const user = AnalyticsSettings.users.find(o => {
          return o.email === quotee
        })

        if (user && user.team === 'sales') {
          let o = {
            data: [],
            borderColor: user.colour,
            backgroundColor: user.colour,
            borderWidth: 2,
            pointStyle: 'rectRot',
            fill: false,
            tension: 0,
            total: 0
          }
          for (const month of this.chartData.labels) {
            if (metrics[month]) {
              o.data.push(Math.round(metrics[month].primaryRevenue))
              o.total += Math.round(metrics[month].primaryRevenue)
            } else {
              o.data.push(0)
            }
          }
          o.label = user.name + ' ' + this.$numeral(o.total).format('$0,0.00')
          revenueData.push(o)
        }
      }

      revenueData.sort((a, b) => {
        return a.total > b.total ? -1 : 1
      })

      return revenueData
    },
    getQuotesDatasets() {
      let revenueData = []
      for (const [quotee, metrics] of Object.entries(this.parsedData)) {
        const user = AnalyticsSettings.users.find(o => {
          return o.email === quotee
        })

        if (user && user.team === 'sales') {
          let o = {
            data: [],
            borderColor: user.colour,
            backgroundColor: user.colour,
            borderWidth: 2,
            pointStyle: 'rectRot',
            fill: false,
            tension: 0,
            total: 0
          }
          for (const month of this.chartData.labels) {
            if (metrics[month]) {
              o.data.push(metrics[month].quotes)
              o.total += metrics[month].quotes
            } else {
              o.data.push(0)
            }
          }
          o.label = user.name + ' ' + o.total
          revenueData.push(o)
        }
      }

      revenueData.sort((a, b) => {
        return a.total > b.total ? -1 : 1
      })

      return revenueData
    },
    getConversionDatasets() {
      let conversionData = []
      for (const [quotee, metrics] of Object.entries(this.parsedData)) {
        const user = AnalyticsSettings.users.find(o => {
          return o.email === quotee
        })

        if (user && user.team === 'sales') {
          let o = {
            data: [],
            borderColor: user.colour,
            backgroundColor: user.colour,
            borderWidth: 2,
            pointStyle: 'rectRot',
            fill: false,
            tension: 0
          }

          let total = 0
          let count = 0

          for (const month of this.chartData.labels) {
            if (metrics[month] && metrics[month].quotes > 0) {
              console.log(metrics[month])
              const val = (metrics[month].convertedQuotes / metrics[month].quotes) * 100
              o.data.push(val)
              total += val
              count++
            } else {
              o.data.push(null)
            }
          }

          o.avg = Math.round(total / count)
          ;(o.label = user.name + ' ' + o.avg + '%'), conversionData.push(o)
        }

        conversionData.sort((a, b) => {
          return a.avg > b.avg ? -1 : 1
        })
      }
      return conversionData
    },
    getFocDatasets() {
      let focData = []
      for (const [quotee, metrics] of Object.entries(this.parsedData)) {
        const user = AnalyticsSettings.users.find(o => {
          return o.email === quotee
        })

        if (user && user.team === 'sales') {
          let o = {
            data: [],
            borderColor: user.colour,
            backgroundColor: user.colour,
            borderWidth: 2,
            pointStyle: 'rectRot',
            fill: false,
            tension: 0,
            total: 0
          }

          for (const month of this.chartData.labels) {
            let val = metrics[month] ? metrics[month].focs : 0
            o.data.push(val)
            o.total += val
          }
          o.label = user.name + ' ' + o.total
          focData.push(o)
        }
      }

      focData.sort((a, b) => {
        return a.total > b.total ? -1 : 1
      })

      return focData
    },
    getAvgOrderDatasets() {
      let avgData = []
      for (const [quotee, metrics] of Object.entries(this.parsedData)) {
        const user = AnalyticsSettings.users.find(o => {
          return o.email === quotee
        })

        if (user && user.team === 'sales') {
          let o = {
            data: [],
            borderColor: user.colour,
            backgroundColor: user.colour,
            borderWidth: 2,
            pointStyle: 'rectRot',
            fill: false,
            tension: 0,
            total: 0
          }

          let revenueTotal = 0
          let orderCount = 0

          for (const month of this.chartData.labels) {
            let avgForMonth =
              metrics[month] && metrics[month].primaryOrders > 0
                ? metrics[month].primaryRevenue / metrics[month].primaryOrders
                : 0

            o.data.push(avgForMonth)

            if (metrics[month]) {
              revenueTotal += metrics[month].primaryRevenue
              orderCount += metrics[month].primaryOrders
            }
          }
          o.avg = Math.round(revenueTotal / orderCount)

          o.label = user.name + ' ' + this.$numeral(Math.round(o.avg)).format('$0,0.00')
          avgData.push(o)
        }
      }

      avgData.sort((a, b) => {
        return a.avg > b.avg ? -1 : 1
      })

      return avgData
    },
    switchView(view) {
      this.loadingData = true
      let datasets
      switch (view) {
        case 'revenue':
          datasets = this.getRevenueDatasets()
          break
        case 'quotes':
          datasets = this.getQuotesDatasets()
          break
        case 'conversion':
          datasets = this.getConversionDatasets()
          break
        case 'foc':
          datasets = this.getFocDatasets()
          break
        case 'avg-order-val':
          datasets = this.getAvgOrderDatasets()
          break
        case 'avg-wait-duration':
          datasets = this.getAvgWaitDatasets()
          break
      }

      this.chartData.datasets = datasets
      this.loadingData = false
    }
  }
}
</script>
