<template>
  <div>
    <v-container fluid id="view-sub-nav">
      <v-radio-group v-model="selectedRegion" @change="parseData" class="ml-4">
        <v-radio label="UK" value="uk" class="mr-6"></v-radio>
        <v-radio label="US" value="us" class="mr-6"></v-radio>
      </v-radio-group>
      <DateRangePicker
        class="d-flex flex-shrink-1"
        :class="loadingData && 'red--text'"
        :empty="true"
        :short="true"
        @change="loadData"
      ></DateRangePicker>
      <v-spacer></v-spacer>
      <v-chip v-if="!loadingData && numOrdersAdded > 0" class="text-body-1 mr-4" color="primary">{{
        numOrdersAdded > 1 ? numOrdersAdded + ' Orders Found' : numOrdersAdded + ' Order Found'
      }}</v-chip>
      <v-btn
        v-if="
          !loadingData &&
            numOrdersAdded > 0 &&
            ['sho@plykea.com', 'kath@plykea.com', 'adam@plykea.com'].includes($store.state.user.email)
        "
        class="ml-2"
        depressed
        outlined
        color="primary"
        @click="csv"
        >Download CSV</v-btn
      >
    </v-container>
    <v-col cols="12">
      <div v-if="!loadingData">
        <v-container fluid>
          <v-row>
            <v-col cols="6">
              <v-card>
                <div class="d-flex justify-space-between align-start">
                  <div>
                    <v-card-title class="text-h6 text-decoration-underline">
                      {{ selectedRegion.toUpperCase() }} Veneer (18mm)
                    </v-card-title>
                    <v-card-text class="text-h4 font-weight-bold"
                      >{{ Math.round(materialsTotal['veneer-standard'].percentage) }}%</v-card-text
                    >
                    <v-card-text class="text-h6 text-medium-emphasis font-weight-regular">
                      Totals {{ selectedRegion === 'uk' ? '£' : '$'
                      }}{{ $numeral(materialsTotal['veneer-standard'].total).format('0,0.00')
                      }}<small class="font-italic">{{
                        materialsTotal['veneer-standard'].pricingExternal > 0
                          ? ' (COGs: ' +
                            (selectedRegion === 'uk' ? '£' : '$') +
                            $numeral(materialsTotal['veneer-standard'].pricingExternal).format('0,0.00') +
                            ')'
                          : ''
                      }}</small>
                    </v-card-text>
                  </div>
                  <div class="d-flex flex-col align-end">
                    <v-chip v-if="materialsTotal['veneer-standard'].ordersCount > 0" class="mr-4 mt-4" color="primary"
                      >{{ materialsTotal['veneer-standard'].ordersCount
                      }}{{ materialsTotal['veneer-standard'].ordersCount > 1 ? ' Orders' : ' Order' }}</v-chip
                    >
                    <v-chip
                      v-if="materialsTotal['veneer-standard'].numMaterialSheetUsed > 0"
                      class="mr-4 mt-4"
                      color="info"
                      >{{ materialsTotal['veneer-standard'].numMaterialSheetUsed
                      }}{{ materialsTotal['veneer-standard'].numMaterialSheetUsed > 1 ? ' Sheets' : ' Sheet' }}</v-chip
                    >
                  </div>
                </div>

                <v-divider></v-divider>
                <div v-for="materialType in materialsData['veneer-standard']" :key="materialType[0].label">
                  <div class="d-flex justify-space-between text-body-2">
                    <v-card-text class="font-weight-bold">
                      {{ materialType[0].materialType.toUpperCase() }} = {{ selectedRegion === 'uk' ? '£' : '$'
                      }}{{
                        $numeral(materialsTotal['veneer-standard'][materialType[0].materialType].total).format(
                          '0,0.00'
                        )
                      }}<small class="font-italic">{{
                        materialsTotal['veneer-standard'][materialType[0].materialType].pricingExternal > 0
                          ? ' (' +
                            (selectedRegion === 'uk' ? '£' : '$') +
                            $numeral(
                              materialsTotal['veneer-standard'][materialType[0].materialType].pricingExternal
                            ).format('0,0.00') +
                            ')'
                          : ''
                      }}</small>
                    </v-card-text>
                    <v-spacer></v-spacer>
                    <v-card-text class="font-weight-bold text-right">
                      {{ materialsTotal['veneer-standard'][materialType[0].materialType].ordersCount
                      }}{{
                        materialsTotal['veneer-standard'][materialType[0].materialType].ordersCount > 1
                          ? ' Orders'
                          : ' Order'
                      }}
                      | {{ materialsTotal['veneer-standard'][materialType[0].materialType].numMaterialSheetUsed
                      }}{{
                        materialsTotal['veneer-standard'][materialType[0].materialType].numMaterialSheetUsed > 1
                          ? ' Sheets'
                          : ' Sheet'
                      }}</v-card-text
                    >
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card>
                <div class="d-flex justify-space-between align-start">
                  <div>
                    <v-card-title class="text-h6 text-decoration-underline"
                      >{{ selectedRegion.toUpperCase() }} Laminate (18mm)</v-card-title
                    >
                    <v-card-text class="text-h4 font-weight-bold">
                      {{ Math.round(materialsTotal['laminate-standard'].percentage) }}%</v-card-text
                    >
                    <v-card-text class="text-h6 text-medium-emphasis font-weight-regular"
                      >Totals {{ selectedRegion === 'uk' ? '£' : '$'
                      }}{{ $numeral(materialsTotal['laminate-standard'].total).format('0,0.00')
                      }}<small class="font-italic">{{
                        materialsTotal['laminate-standard'].pricingExternal > 0
                          ? ' (COGs: ' +
                            (selectedRegion === 'uk' ? '£' : '$') +
                            $numeral(materialsTotal['laminate-standard'].pricingExternal).format('0,0.00') +
                            ')'
                          : ''
                      }}</small></v-card-text
                    >
                  </div>
                  <div class="d-flex flex-col align-end">
                    <v-chip v-if="materialsTotal['laminate-standard'].ordersCount > 0" class="mr-4 mt-4" color="primary"
                      >{{ materialsTotal['laminate-standard'].ordersCount
                      }}{{ materialsTotal['laminate-standard'].ordersCount > 1 ? ' Orders' : ' Order' }}</v-chip
                    >
                    <v-chip
                      v-if="materialsTotal['laminate-standard'].numMaterialSheetUsed > 0"
                      class="mr-4 mt-4"
                      color="info"
                      >{{ materialsTotal['laminate-standard'].numMaterialSheetUsed
                      }}{{
                        materialsTotal['laminate-standard'].numMaterialSheetUsed > 1 ? ' Sheets' : ' Sheet'
                      }}</v-chip
                    >
                  </div>
                </div>
                <v-divider></v-divider>
                <div
                  class="d-flex flex-column"
                  v-for="materialType in materialsData['laminate-standard']"
                  :key="materialType[0].label"
                >
                  <div class="d-flex justify-space-between text-body-2">
                    <v-card-text class="font-weight-bold">
                      {{ materialType[0].materialType.toUpperCase() }} = {{ selectedRegion === 'uk' ? '£' : '$'
                      }}{{
                        $numeral(materialsTotal['laminate-standard'][materialType[0].materialType].total).format(
                          '0,0.00'
                        )
                      }}<small class="font-italic">{{
                        materialsTotal['laminate-standard'][materialType[0].materialType].pricingExternal > 0
                          ? ' (' +
                            (selectedRegion === 'uk' ? '£' : '$') +
                            $numeral(
                              materialsTotal['laminate-standard'][materialType[0].materialType].pricingExternal
                            ).format('0,0.00') +
                            ')'
                          : ''
                      }}</small>
                    </v-card-text>
                    <v-spacer></v-spacer>
                    <v-card-text class="font-weight-bold text-right"
                      >{{ materialsTotal['laminate-standard'][materialType[0].materialType].ordersCount
                      }}{{
                        materialsTotal['laminate-standard'][materialType[0].materialType].ordersCount > 1
                          ? ' Orders'
                          : ' Order'
                      }}
                      | {{ materialsTotal['laminate-standard'][materialType[0].materialType].numMaterialSheetUsed
                      }}{{
                        materialsTotal['laminate-standard'][materialType[0].materialType].numMaterialSheetUsed > 1
                          ? ' Sheets'
                          : ' Sheet'
                      }}</v-card-text
                    >
                    <v-card-actions>
                      <v-btn @click="show = !show" plain
                        ><v-icon v-if="show">mdi-chevron-up</v-icon><v-icon v-else>mdi-chevron-down</v-icon></v-btn
                      >
                    </v-card-actions>
                  </div>

                  <v-expand-transition>
                    <div v-show="show">
                      <v-divider></v-divider>
                      <div
                        class="d-flex justify-space-between text-body-2"
                        v-for="(material, index) in materialsData['laminate-standard'][materialType[0].materialType]"
                        :key="material.ids[index]"
                      >
                        <v-card-text>
                          {{ material.label }} = {{ selectedRegion === 'uk' ? '£' : '$'
                          }}{{ $numeral(material.total).format('0,0.00')
                          }}<small class="font-italic">{{
                            material.pricingExternal > 0
                              ? ' (' +
                                (selectedRegion === 'uk' ? '£' : '$') +
                                $numeral(material.pricingExternal).format('0,0.00') +
                                ')'
                              : ''
                          }}</small>
                        </v-card-text>
                        <v-spacer></v-spacer>
                        <v-card-text class="text-right"
                          >{{ material.ordersCount }}{{ material.ordersCount > 1 ? ' Orders' : ' Order' }} |
                          {{ material.numMaterialSheetUsed
                          }}{{ material.numMaterialSheetUsed > 1 ? ' Sheets' : ' Sheet' }}</v-card-text
                        >
                      </div>
                      <v-divider></v-divider>
                    </div>
                  </v-expand-transition>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div v-else class="d-flex flex-column align-center" height="300">
        <div class="text-body-1 text-uppercase font-weight-bold my-6">{{ loadingMessage }}</div>
        <div class="mt-auto">
          <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
        </div>
      </div>
    </v-col>
  </div>
</template>

<script>
import filesaver from 'file-saver'
import DateRangePicker from '@/components/ui/DateRangePicker'
// import AnalyticsSettings from '@/js/analytics-settings'

export default {
  name: 'MaterialsInformation',
  components: { DateRangePicker },
  data() {
    return {
      loadingData: true,
      loadingMessage: 'Please pick a region and date range',
      selectedRegion: 'uk',
      dataSnap: { orders: [], quotes: [] },
      dateCreatedFrom: null,
      dateCreatedTo: null,
      numOrdersAdded: 0,
      materialsData: {
        'veneer-standard': {}, // 18mm
        'laminate-standard': {} // 18mm
      },
      materialsTotal: {
        'veneer-standard': {},
        'laminate-standard': {}
      },
      show: false
    }
  },
  methods: {
    async loadData(range) {
      this.loadingData = true
      this.dataSnap = { orders: [], quotes: [] }

      this.loadingMessage = 'Loading data...'

      // 1. set up date range
      let months = []
      let dateStart = this.$moment(range[0])
      let dateEnd = this.$moment(range[1]).endOf('day')

      while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
        months.push(dateStart.format('MMM YY'))
        dateStart.add(1, 'month')
      }

      this.loadingMessage = 'Retrieving orders...'

      this.dateCreatedFrom = range[0].toDate()
      this.dateCreatedTo = range[1].endOf('day').toDate()

      // 2. pull orders data within date range
      const ordersSnap = await this.$db
        .collection('orders')
        .where('type', '==', 'mega-admin')
        .where('foc', '==', false)
        .where('dateCreated', '>=', range[0].toDate())
        .where('dateCreated', '<=', range[1].endOf('day').toDate())
        .get()

      this.loadingMessage = `Filtering quotes...(0/${ordersSnap.docs.length})`

      // 3. find quote matching order's quoteID
      let quoteCount = 0
      for (const order of ordersSnap.docs) {
        this.loadingMessage = `Filtering quotes...(${quoteCount + 1}/${ordersSnap.docs.length})`
        quoteCount++
        this.dataSnap.orders.push(order.data())
        let quoteSnap = await this.$db
          .collection('quotes')
          .doc(order.data().quoteID)
          .get()
        this.dataSnap.quotes.push(quoteSnap.data())
      }

      this.loadingMessage = 'Parsing data...'
      this.parseData()
      this.loadingData = false
    },
    async parseData() {
      this.numOrdersAdded = 0
      this.materialsData = {
        'veneer-standard': {}, // 18mm
        'laminate-standard': {} // 18mm
      }
      this.materialsTotal = {
        'veneer-standard': {},
        'laminate-standard': {}
      }
      let filteredVersions = []

      // 1. filter quotes by region
      this.dataSnap.quotes.forEach(quote => {
        if (
          (this.selectedRegion === 'uk' && quote.region === 'uk') ||
          (this.selectedRegion === 'us' && quote.region === 'us')
        ) {
          // 2. then find versions within that quote matching order's versionID
          this.dataSnap.orders.forEach(order => {
            let paidVersion = quote.versions.find(version => version.id === order.versionID)
            if (paidVersion) {
              filteredVersions.push(paidVersion)
            }
          })
        }
      })

      this.numOrdersAdded = filteredVersions.length

      // 3. set materials used in that version into the global variables of each material category
      filteredVersions.forEach(version => {
        for (const material of version.materials) {
          if (!version.pricing?.fronts[material.id]?.total > 0) continue
          if (material.hasGrain) {
            let lastIndex = material.label.lastIndexOf(' ')
            let veneerMaterialType = material.label.substring(0, lastIndex).toLowerCase()
            // first check if the material is already in the global variables
            let veneerStandardExists = false
            if (this.materialsData['veneer-standard'][veneerMaterialType]) {
              veneerStandardExists = this.materialsData['veneer-standard'][veneerMaterialType].find(
                m => m.label === material.label
              )
            }
            switch (material.thickness) {
              case 18:
                // if it is, add the material id to the array of ids
                if (veneerStandardExists) {
                  !veneerStandardExists.ids.includes(material.id) && veneerStandardExists.ids.push(material.id)
                } else {
                  if (this.materialsData['veneer-standard'][veneerMaterialType]) {
                    // if the material type exists, add the material to the array
                    this.materialsData['veneer-standard'][veneerMaterialType].push({
                      label: material.label,
                      materialType: veneerMaterialType,
                      ids: [material.id],
                      total: 0,
                      pricingExternal: 0, // sheetCost + faceLaquer
                      numMaterialSheetUsed: 0,
                      ordersCount: 0
                    })
                  } else {
                    // if not, add object with material label
                    this.materialsData['veneer-standard'][veneerMaterialType] = [
                      {
                        label: material.label,
                        materialType: veneerMaterialType,
                        ids: [material.id],
                        total: 0,
                        pricingExternal: 0, // sheetCost + faceLaquer
                        numMaterialSheetUsed: 0,
                        ordersCount: 0
                      }
                    ]
                  }
                }
                break
            }
          } else if (material.requiresColourCode) {
            let laminateMaterialType = material.label.split(' ')[0].toLowerCase()
            let lamStandardExists = false
            if (this.materialsData['laminate-standard'][laminateMaterialType]) {
              lamStandardExists = this.materialsData['laminate-standard'][laminateMaterialType].find(
                m => m.label === material.label
              )
            }
            switch (material.thickness) {
              case 18:
                if (lamStandardExists) {
                  !lamStandardExists.ids.includes(material.id) && lamStandardExists.ids.push(material.id)
                } else {
                  if (this.materialsData['laminate-standard'][laminateMaterialType]) {
                    this.materialsData['laminate-standard'][laminateMaterialType].push({
                      label: material.label,
                      materialType: laminateMaterialType,
                      ids: [material.id],
                      total: 0,
                      pricingExternal: 0, // sheetCost
                      numMaterialSheetUsed: 0,
                      ordersCount: 0
                    })
                  } else {
                    this.materialsData['laminate-standard'][laminateMaterialType] = [
                      {
                        label: material.label,
                        materialType: laminateMaterialType,
                        ids: [material.id],
                        total: 0,
                        pricingExternal: 0, // sheetCost
                        numMaterialSheetUsed: 0,
                        ordersCount: 0
                      }
                    ]
                  }
                }
                break
            }
          } else {
            console.log('Material not found in any category')
            continue
          }
        }

        // 4. set the total of each material used in the version
        for (const category of Object.keys(this.materialsData)) {
          for (const materialType of Object.keys(this.materialsData[category])) {
            if (this.materialsData[category][materialType].length === 0) {
              continue
            }
            for (const material of this.materialsData[category][materialType]) {
              for (const id of material.ids) {
                // a) Fronts
                let frontMaterial = version.pricing?.fronts[id]
                if (frontMaterial && frontMaterial.total > 0) {
                  // incase material is not used, check for total
                  material.total += frontMaterial?.total
                  material.pricingExternal +=
                    this.selectedRegion === 'uk' ? frontMaterial?.pricing?.external : frontMaterial?.cost
                  material.numMaterialSheetUsed += frontMaterial?.requiredSheetsRounded
                  material.ordersCount++
                }
              }
            }
          }
        }
      })

      for (const category of Object.keys(this.materialsData)) {
        this.materialsTotal[category] = {
          total: 0,
          percentage: 0,
          pricingExternal: 0, // sheetCost + faceLaquer
          numMaterialSheetUsed: 0,
          ordersCount: 0
        }

        for (const materialType of Object.keys(this.materialsData[category])) {
          if (this.materialsData[category][materialType].length === 0) {
            continue
          }
          this.materialsTotal[category][materialType] = {
            label: materialType.charAt(0).toUpperCase() + materialType.substring(1).toLowerCase(),
            total: 0,
            pricingExternal: 0,
            numMaterialSheetUsed: 0,
            ordersCount: 0
          }

          // 5. tally up the total - of each material for each material type
          this.materialsData[category][materialType].forEach(material => {
            // totals
            this.materialsTotal[category][materialType].total += material.total
            // external pricing tally
            this.materialsTotal[category][materialType].pricingExternal += material.pricingExternal
            // sheet used tally
            this.materialsTotal[category][materialType].numMaterialSheetUsed += material.numMaterialSheetUsed
            // order count tally
            this.materialsTotal[category][materialType].ordersCount += material.ordersCount
          })

          // 6. tally category totals
          // totals
          this.materialsTotal[category].total += this.materialsTotal[category][materialType]?.total
          // external pricing tally
          this.materialsTotal[category].pricingExternal += this.materialsTotal[category][materialType]?.pricingExternal
          // sheet used tally
          this.materialsTotal[category].numMaterialSheetUsed += this.materialsTotal[category][
            materialType
          ]?.numMaterialSheetUsed
          // order count tally
          this.materialsTotal[category].ordersCount += this.materialsTotal[category][materialType]?.ordersCount
        }
      }

      // 7. calculate the percentage - of each material category
      // percentage
      for (const category of Object.keys(this.materialsData)) {
        if (this.materialsTotal[category].total === 0) {
          this.materialsTotal[category].percentage = 0
        } else {
          this.materialsTotal[category].percentage =
            (this.materialsTotal[category].total /
              (this.materialsTotal['veneer-standard'].total + this.materialsTotal['laminate-standard'].total)) *
            100
        }
      }

      // console.log('materialsData', this.materialsData)
      // console.log('materialsTotal', this.materialsTotal)
    },
    csv() {
      // 1. set up CSV
      let csv = ''
      // insert date
      if (this.dateCreatedFrom && this.dateCreatedTo) {
        csv += `Date Created From: ${this.$moment(this.dateCreatedFrom).format(
          'DD/MM/YYYY'
        )}, Date Created To: ${this.$moment(this.dateCreatedTo).format('DD/MM/YYYY')}\n\n`
      }

      // 2. add tally data at the beginning
      // heading
      csv += `Veneer Percentage (%),Veneer Totals (${this.selectedRegion === 'uk' ? '£' : '$'}),Veneer COGs Tally (${
        this.selectedRegion === 'uk' ? '£' : '$'
      }),Veneer Order Tally (count),Veneer Sheets Used Tally (qty),,Laminate Percentage (%),Laminate Totals (${
        this.selectedRegion === 'uk' ? '£' : '$'
      }),Laminate COGs Tally (${
        this.selectedRegion === 'uk' ? '£' : '$'
      }),Laminate Order Tally (count),Laminate Sheets Used Tally (qty)\n`
      // insert tally data
      let venTotal = this.materialsTotal['veneer-standard']
      let lamTotal = this.materialsTotal['laminate-standard']
      csv += `${Math.round(venTotal.percentage)},${venTotal.total.toFixed(2)},${venTotal.pricingExternal.toFixed(2)},${
        venTotal.ordersCount
      },${venTotal.numMaterialSheetUsed},,${Math.round(lamTotal.percentage)},${lamTotal.total.toFixed(
        2
      )},${lamTotal.pricingExternal.toFixed(2)},${lamTotal.ordersCount},${lamTotal.numMaterialSheetUsed}\n\n`

      // 3. insert laminate material type data
      // title
      csv += ',,,,,,LAMINATE MATERIAL GROUPED\n'
      // heading
      csv += `,,,,,,Laminate Material Type,Laminate Material Type Total (${
        this.selectedRegion === 'uk' ? '£' : '$'
      }),Laminate Type COGs (${
        this.selectedRegion === 'uk' ? '£' : '$'
      }),Laminate Type Order (count),Laminate Type Sheet Used (qty)\n`

      // 4. create an array of CSV lines for each laminate material type
      let laminateTypeDataStringArray = []

      // 5. laminate type data

      let lamTypeTotalObjectValues = Object.values(this.materialsTotal['laminate-standard'])
      let lamTypeTotal = lamTypeTotalObjectValues.filter(lamValue => {
        return typeof lamValue === 'object'
      })

      if (lamTypeTotal.length > 0) {
        lamTypeTotal.forEach(materialType => {
          let laminateTypeDataString = `,,,,,,${materialType.label},${materialType.total.toFixed(
            2
          )},${materialType.pricingExternal.toFixed(2)},${materialType.ordersCount},${
            materialType.numMaterialSheetUsed
          }`
          laminateTypeDataStringArray.push(laminateTypeDataString)
        })
      }

      csv += laminateTypeDataStringArray.join('\n')
      csv += '\n\n'

      // 3. insert veneer and laminate data
      // heading
      csv += `Veneer Material Label,Veneer Material Total (${this.selectedRegion === 'uk' ? '£' : '$'}),Veneer COGs (${
        this.selectedRegion === 'uk' ? '£' : '$'
      }),Veneer Order (count),Veneer Sheet Used (qty),,Laminate Material Label,Laminate Material Total (${
        this.selectedRegion === 'uk' ? '£' : '$'
      }),Laminate COGs (${this.selectedRegion === 'uk' ? '£' : '$'}),Laminate Order (count),Laminate Sheet Used (qty)\n`

      // 4. create an array of CSV lines for each material category
      let veneerDataStringArray = []
      let laminateDataStringArray = []

      // 5. veneer data
      let venData = Object.values(this.materialsData['veneer-standard'])

      if (venData.length > 0) {
        venData.forEach(materialType => {
          materialType.forEach(material => {
            let veneerDataString = `${material.label},${material.total.toFixed(2)},${material.pricingExternal.toFixed(
              2
            )},${material.ordersCount},${material.numMaterialSheetUsed}`
            veneerDataStringArray.push(veneerDataString)
          })
        })
      }

      // 6. laminate data
      let lamData = Object.values(this.materialsData['laminate-standard'])

      if (lamData.length > 0) {
        lamData.forEach(materialType => {
          materialType.forEach(material => {
            let laminateDataString = `${material.label},${material.total.toFixed(2)},${material.pricingExternal.toFixed(
              2
            )},${material.ordersCount},${material.numMaterialSheetUsed}`
            laminateDataStringArray.push(laminateDataString)
          })
        })
      }

      // 7. create a dataStringArray compiling both, veneer and laminate dataStringArray
      let dataStringArray = []
      // check which array is longer
      if (veneerDataStringArray.length > laminateDataStringArray.length) {
        for (let i = 0; i < veneerDataStringArray.length; i++) {
          if (laminateDataStringArray[i]) {
            dataStringArray.push(veneerDataStringArray[i] + ',,' + laminateDataStringArray[i] + '\n')
          } else {
            dataStringArray.push(veneerDataStringArray[i] + '\n')
          }
        }
      } else if (laminateDataStringArray.length > veneerDataStringArray.length) {
        for (let i = 0; i < laminateDataStringArray.length; i++) {
          if (veneerDataStringArray[i]) {
            dataStringArray.push(veneerDataStringArray[i] + ',,' + laminateDataStringArray[i] + '\n')
          } else {
            dataStringArray.push(',,,,,,' + laminateDataStringArray[i] + '\n')
          }
        }
      } else {
        for (let i = 0; i < veneerDataStringArray.length; i++) {
          dataStringArray.push(veneerDataStringArray[i] + ',,' + laminateDataStringArray[i] + '\n')
        }
      }

      // 8. insert dataStringArray to csv in order & file save
      dataStringArray.forEach(dataString => {
        csv += dataString
      })

      let blob = new Blob([csv], { type: 'text/csv' })

      if (this.dateCreatedFrom && this.dateCreatedTo) {
        filesaver.saveAs(
          blob,
          `${this.selectedRegion}-18mm-material-info-[${this.$moment(this.dateCreatedFrom).format(
            'DD/MM/YYYY'
          )}-${this.$moment(this.dateCreatedTo).format('DD/MM/YYYY')}].csv`
        )
      } else {
        filesaver.saveAs(blob, `${this.selectedRegion}-18mm-material-info.csv`)
      }
    }
  }
}
</script>
